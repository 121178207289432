import React from "react";
import closeButton from "../images/closeIcon.svg";

type ModalProps = {
  toggleVisibility: () => void; // Passed from parent to toggle modal visibility
  header: string;
  content: JSX.Element;
};

export class Modal extends React.Component<ModalProps> {
  render() {
    const modalId = "modal-" + window.crypto.randomUUID();

    const closeModal = (event: React.MouseEvent<HTMLElement>) => {
      /* 
        Fade Out, Then Hide Modal

        Allowing user to close the modal by clicking on the close button or anywhere outside of the modal
      */
      event.stopPropagation();
      const modal = document.getElementById(modalId);
      if (!modal?.hasAttribute("closeListenerAttached")) {
        modal?.setAttribute("closeListenerAttached", "true");
        modal?.addEventListener("animationend", () => {
          this.props.toggleVisibility();
        });
        modal?.classList.toggle("animate__fadeIn");
        modal?.classList.toggle("animate__fadeOut");
      }
    };
    return (
      <>
        <div
          onClick={(event) => closeModal(event)}
          id={modalId}
          className="animate__animated animate__faster animate__fadeIn fixed top-0 left-0 z-40 h-full w-full overflow-y-auto bg-[rgba(0,0,0,.4)]"
        >
          <div
            className="z-50 flex flex-col items-center"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <div className="modal mt-10 w-3/4 max-w-prose rounded-lg bg-white md:mt-28">
              <div className="rounded-t-md bg-slate-100">
                <div className="mx-3 flex justify-between px-2 md:mx-1">
                  <h1 className="rounded-t-md bg-slate-100 py-2 font-montserrat text-2xl">
                    {this.props.header}
                  </h1>
                  <button onClick={(event) => closeModal(event)}>
                    <img src={closeButton} alt="" />
                  </button>
                </div>
              </div>
              {this.props.content}
            </div>
          </div>
        </div>
      </>
    );
  }
}
