import React from "react";
import { ProjectCard } from "./ProjectCard";
import ramImage from "../../images/ram.jpg";
import cryptoImage from "../../images/crypto.jpg";
import rsaImage from "../../images/rsa.png";
import projectBoostImage from "../../images/projectBoost.png";
import tcalImage from "../../images/tcalCardImage.png";
import chronoChannelImage from "../../images/chronoChannel.png";
import { projectCardContent } from "../CardContent";

export class Projects extends React.Component {
  render() {
    return (
      <div
        className="container mx-auto flex flex-col justify-center"
        id="portfolio-section"
      >
        <h2 className="lineAfter lineBefore my-5 text-center text-4xl text-white">
          Portfolio
        </h2>
        <p className="mx-auto max-w-prose px-2 text-center font-inter text-white">
          Below are code samples from various points throughout my education and
          career. While it is not an exhaustive list, it is intended to
          highlight different parts of my skill set and projects that I may not
          have been able to share on my resume, GitHub, or other platforms.
          <br />
          <br />
          Click on any of the cards to learn a bit more about the project and
          possibly download some source code if you're curious.
        </p>
        {/* Project Cards Flex Container */}

        <div className="flex flex-col flex-wrap justify-center md:flex-row">
          <ProjectCard
            header="Chrono Channel"
            category="Twitch Extension"
            image={chronoChannelImage}
            paragraph={projectCardContent.chronoChannel.shortDescription}
            modalContent={projectCardContent.chronoChannel.modalContent}
          />

          <ProjectCard
            header="T-Cal: Stream Scheduler"
            category="Google Calendar Add-On"
            image={tcalImage}
            paragraph={projectCardContent.tcal.shortDescription}
            modalContent={projectCardContent.tcal.modalContent}
          />

          <ProjectCard
            header="Project Boost"
            category="Unity Game Development"
            image={projectBoostImage}
            paragraph={projectCardContent.projectBoost.shortDescription}
            modalContent={projectCardContent.projectBoost.modalContent}
          />

          <ProjectCard
            header="Memory Allocation"
            category="Systems Programming"
            image={ramImage}
            paragraph={projectCardContent.memoryAllocation.shortDescription}
            modalContent={projectCardContent.memoryAllocation.modalContent}
          />

          <ProjectCard
            header="Block Ciphers"
            category="Cryptography (Symmetric)"
            image={cryptoImage}
            paragraph={projectCardContent.blockCiphers.shortDescription}
            modalContent={projectCardContent.blockCiphers.modalContent}
          />

          <ProjectCard
            header="RSA"
            category="Cryptography (Asymmetric)"
            image={rsaImage}
            paragraph={projectCardContent.rsa.shortDescription}
            modalContent={projectCardContent.rsa.modalContent}
          />
        </div>
      </div>
    );
  }
}
