import React from "react";
import headshot from "../images/isaac_ige_sq.webp";

export class Hero extends React.Component {
  componentDidMount() {
    const headerTextSection = document.getElementById("headerTextSection");
    headerTextSection?.classList.add(
      "animate__animated",
      "animate__fadeIn",
      "md:block"
    );
    headerTextSection?.classList.remove("hidden", "md:hidden");

    const heroImage = document.getElementById("heroImage");
    heroImage?.classList.add("animate__animated", "animate__fadeIn");
    heroImage?.classList.remove("hidden");
  }

  render() {
    return (
      <div className="container mx-auto flex flex-col justify-evenly md:flex-row">
        <div className="flex flex-col justify-center">
          <div id="headerTextSection" className="hidden md:hidden">
            <h1
              id="headerText"
              className="mx-3 text-center font-montserrat text-7xl text-white"
            >
              Isaac Ige
            </h1>
            <h2
              id="headerSubtext"
              className="mx-3 max-w-prose py-4 text-center font-montserrat text-xl text-white"
            >
              Full Stack Developer
            </h2>
          </div>
        </div>
        <div className="flex items-center justify-center pt-3">
          <img id="heroImage" src={headshot} className="hidden w-1/2 rounded-full" alt="" />
        </div>
      </div>
    );
  }
}
