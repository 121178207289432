import React from "react";

import typescriptLogo from "../../images/skill_icons/typescript.svg";
import javascriptLogo from "../../images/skill_icons/javascript.svg";
import awsLogo from "../../images/skill_icons/aws.png";
import reactLogo from "../../images/skill_icons/react.svg";
import alexaLogo from "../../images/skill_icons/alexa.png";
import cPlusPlusLogo from "../../images/skill_icons/cplusplus.svg";
import pythonLogo from "../../images/skill_icons/python.svg";
import { SkillCard } from "./SkillCard";
import { skillCardContent } from "../CardContent";

export class Skills extends React.Component {
  render() {
    return (
      <div
        className="container mx-auto flex flex-col justify-center py-8"
        id="skills-section"
      >
        <h2 className="lineAfter lineBefore py-4 text-center text-4xl text-white">
          Skills
        </h2>
        {/* Skills Flex Container */}
        <p className="align-center m-2 text-center font-inter text-white">
          Click on any of the skill cards to learn a bit more about my
          experience
        </p>
        <div className="div flex flex-wrap justify-center">
          {/* Skill Cards */}
          <SkillCard
            header="TypeScript"
            image={typescriptLogo}
            modalContent={skillCardContent.typescript}
          />
          <SkillCard
            header="JavaScript"
            image={javascriptLogo}
            modalContent={skillCardContent.javascript}
          />
          <SkillCard
            header="Amazon Web Services"
            classOverrides={{
              imageContainer: "mx-auto mt-6 mb-4 w-16 md:mb-0",
            }}
            image={awsLogo}
            modalContent={skillCardContent.aws}
          />
          <SkillCard
            header="React"
            image={reactLogo}
            modalContent={skillCardContent.react}
          />
          <SkillCard
            header="Python"
            image={pythonLogo}
            modalContent={skillCardContent.python}
          />
          <SkillCard
            header="Alexa Skills Kit"
            classOverrides={{
              imageContainer: "mx-auto w-32",
            }}
            image={alexaLogo}
            modalContent={skillCardContent.alexa}
          />
          <SkillCard
            header="C / C++"
            image={cPlusPlusLogo}
            modalContent={skillCardContent.cplusplus}
          />
        </div>
      </div>
    );
  }
}
