import React from "react";
import { Modal } from "../Modal";

type ProjectProps = {
  image: string;
  header: string;
  category: string;
  paragraph: string;
  modalContent: JSX.Element;
};

type ProjectState = {
  showModal: boolean;
};
export class ProjectCard extends React.Component<ProjectProps, ProjectState> {
  constructor(props: ProjectProps) {
    super(props);
    this.state = { showModal: false };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    if (this.state.showModal) {
      this.setState({ ...this.state, showModal: false });
    } else {
      this.setState({ ...this.state, showModal: true });
    }
  }

  render() {
    return (
      <>
        {this.state.showModal ? (
          <Modal
            header={this.props.header}
            toggleVisibility={this.toggleModal}
            content={this.props.modalContent}
          />
        ) : null}
        <button
          className="div my-8 flex justify-center md:max-w-[33%]"
          onClick={this.toggleModal}
        >
          <div className="flex justify-center">
            <div className="mx-5 rounded-[30px] bg-japaneseIndigo text-left shadow-lg md:hover:bg-japaneseIndigo-light">
              <img
                src={this.props.image}
                className="w-full rounded-t-[30px]"
                alt=""
              />
              <h2 className="pl-4 pt-4 font-montserrat text-2xl text-white">
                {this.props.header}
              </h2>
              <h2 className="pl-4 font-montserrat text-xl text-white">
                {this.props.category}
              </h2>
              <p className="p-4 font-montserrat text-white">
                {this.props.paragraph}
              </p>
            </div>
          </div>
        </button>
      </>
    );
  }
}
