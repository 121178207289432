import React from "react";

export class About extends React.Component {
  render() {
    return (
      <div
        className="container mx-auto flex flex-col justify-evenly py-8 md:flex-row"
        id="about-section"
      >
        {/* About Me Block */}
        <div className="flex flex-col">
          {/* Nested Flex Container for Horizontal Line */}
          <div className="flex flex-row items-center text-xl">
            <h3 className="lineAfter lineAfter p-4 font-inter text-reddishOrange">
              About Me
            </h3>
          </div>
          <p className="max-w-prose p-4 font-montserrat text-white">
            I'm an open-minded, knowledge-hungry techie that has been fascinated
            with computers for as long as I can remember. An interest that
            sparked with wanting to learn how to create a video game has
            developed into an ambition to help people with technology wherever
            possible.
            <br />
            <br />
            My programming strengths are in TypeScript, Python, and C++.
            <br />
            <br />
            I got my BS in Computer Science from The University of Tennessee,
            Knoxville, and my MS in Software Engineering at Carnegie Mellon
            University. <br />
          </p>
        </div>
        {/* Query / Discussion Block */}
        <div className="flex flex-col">
          <h3 className="p-4 font-inter text-xl text-white ">Eager to Learn</h3>
          <p className="max-w-prose p-4 font-montserrat text-white">
            I love keeping up with the latest technologies and exploring how we
            can use them to solve real-world problems. When building this
            website, I took it as an opportunity to learn a new CSS framework
            <span className="inline"> - </span>
            <a
              href="https://tailwindcss.com/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-200 md:hover:underline"
            >
              Tailwind
            </a>
            . It was a great experience, and I look forward to using it in
            future projects.
          </p>
        </div>
      </div>
    );
  }
}
