import sqImage from "../images/sq.png";

export const skillCardContent: Record<string, JSX.Element> = {
  typescript: (
    <p className="flex px-3 py-5">
      I began using TypeScript in January 2021. Most of my experience comes from
      working on interactive voice experiences at Volley. It was great to
      experience the benefits that static typing brought into the codebases with
      which I worked.
    </p>
  ),
  javascript: (
    <p className="flex px-3 py-5">
      I decided to separate TypeScript and JavaScript into two skills in case
      someone who is unaware of their relationship is viewing this website. I am
      familiar with using it in a frontend, browser context, as well as in the
      backend with Node.js.
    </p>
  ),
  aws: (
    <p className="flex px-3 py-5">
      I first got hands-on experience with AWS in May of 2018 while interning at
      a credit union. I helped them set up their first virtual private cloud.
      Furthermore, I was able to leverage SageMaker to help detect abnormal
      activity in transaction counts.
      <br />
      <br />I experimented with various products within AWS while working on
      hobbies and side projects. At Volley, I became familiar with DynamoDB,
      ECS/EKS, S3, and more.
      <br />
      <br /> I do have some exposure to other cloud platforms, but AWS is the
      one with which I am most familiar.
    </p>
  ),
  react: (
    <p className="flex px-3 py-5">
      My first experience with React was going through Codecademy's React 101
      course. I familiarized myself with the syntax but only got real experience
      with a complete application once I started working at Volley.
      <br />
      <br />
      While working on the Song Quiz team, I helped completely redesign the
      visual experience for devices with screens with a React-based frontend.
      <br />
      <br />I built the website you are looking at now with React and Tailwind.
    </p>
  ),
  python: (
    <p className="flex px-3 py-5">
      I started learning Python about halfway through my undergraduate studies -
      sometime around 2017. I used it primarily for classes involving
      cryptography and machine learning. In this coursework, I got some hands-on
      experience with some of the most popular packages such as numpy, pandas,
      matplotlib, and scikit-learn.
      <br />
      <br />
      While interning at a credit union, I utilized Python and Flask to build a
      RESTFul API that made accessing data from some of their more rigid,
      proprietary software easier.
      <br />
      <br />I got more experience while doing freelance work and creating
      browser automation scripts with Selenium WebDriver.
    </p>
  ),
  alexa: (
    <>
      <div className="flex justify-center pt-5">
        <img src={sqImage} alt="" />
      </div>
      <p className="flex px-3 py-5">
        At Volley, I primarily worked on Song Quiz, one of the Alexa platform's
        most popular games.
        <br />
        <br />
        The emerging space of voice tech forces you to approach things
        differently when you don't always have the luxury of relying on visual
        aid.
        <br />
        <br />I helped work through various challenges around natural language
        understanding (NLU) and Automatic Speech Recognition (ASR).
      </p>
    </>
  ),
  cplusplus: (
    <p className="flex px-3 py-5">
      My BS in Computer Science was primarily in C & C++. I have yet to leverage
      it much in a professional context. However, my understanding of
      fundamental data structures, algorithms, and systems was taught via these
      languages.
      <br />
      <br />
      During my master's program, I took a course titled "How to Write Fast
      Code." Here, I got some more hands-on experience with leveraging parallel
      programming (Open MP) and CUDA to improve the performance of applications.
    </p>
  ),
};

export const projectCardContent: Record<
  string,
  { shortDescription: string; modalContent: JSX.Element }
> = {
  memoryAllocation: {
    shortDescription:
      "A lab assignment from a Systems Programming class. Re-implements: malloc, free, calloc, and realloc by leveraging system calls such as sbrk.",
    modalContent: (
      <>
        <div className="flex px-3 py-5">
          <p>
            This lab assignment from my systems programming class helped me get
            a firm grasp of how memory allocation works. This assignment did not
            require me to coalesce free blocks.
            <br />
            <div className="mt-5 flex flex-col justify-center md:flex-row md:justify-between">
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="code/malloc_writeup.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Original Writeup
                </a>
              </button>
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="code/malloc.zip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Source Code
                </a>
              </button>
            </div>
          </p>
        </div>
      </>
    ),
  },
  blockCiphers: {
    shortDescription:
      "A collection of Python 3 scripts that provide encryption/decryption implementations for block cipher modes - cipher block chaining (CBC) and Counter (CTR).",
    modalContent: (
      <>
        <div className="flex px-3 py-5">
          <p>
            This was a lab assignment from an applied cryptography class during
            undergrad. The assigment helped me familiarize with concepts of
            symmetric key cryptography. This Python 3 implementation of some
            block ciphers utilizes the AES ECB implementation from
            <span className="inline"> </span>
            <a
              href="https://www.pycryptodome.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 md:hover:underline"
            >
              PyCryptodome
            </a>
            .
            <br />
            <br />
            The Makefile was provided by the instructor and designed for pacman
            on Arch Linux.
            <br />
            <div className="mt-5 flex flex-col justify-center md:flex-row md:justify-between">
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="code/block_cipher_writeup.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Original Writeup
                </a>
              </button>
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="code/block_ciphers.zip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Source Code
                </a>
              </button>
            </div>
          </p>
        </div>
      </>
    ),
  },
  rsa: {
    shortDescription:
      "A Python 3 implementation of RSA key generation, encryption, and decryption.",
    modalContent: (
      <>
        <div className="flex px-3 py-5">
          <p>
            This was a lab assignment from an applied cryptography class during
            undergrad. This assignment helped me solidify my understanding of
            asymmetric key cryptography. It relies on the
            <span className="inline"> </span>
            <a
              href="https://www.pycryptodome.org/"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 md:hover:underline"
            >
              PyCryptodome package
            </a>
            .
            <br />
            <br />
            The Makefile was provided by the instructor and designed for pacman
            on Arch Linux.
            <br />
            <div className="mt-5 flex flex-col justify-center md:flex-row md:justify-between">
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="code/rsa_writeup.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Original Writeup
                </a>
              </button>
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="code/rsa.zip"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Source Code
                </a>
              </button>
            </div>
          </p>
        </div>
      </>
    ),
  },
  projectBoost: {
    shortDescription:
      "A small unity game I created while following a course from Gamedev.tv. Navigate a rocket through space and avoid obstacles to reach your landing pad!",
    modalContent: (
      <>
        <div className="flex px-3 py-5">
          <p>
            This project was part of the "Complete C# Unity Game Developer 3D"
            course from
            <span className="inline"> </span>
            <a
              href="https://www.gamedev.tv/p/complete-c-unity-game-developer-3d-online-course-2020"
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-900 md:hover:underline"
            >
              Gamedev.tv
            </a>
            .
            <br />
            <br />
            This project taught me the basics of Unity and game development
            concepts such as movement, collision, particle systems, and much
            more.
            <br />
            <br />
            <strong>Controls:</strong>
            <ul className="ml-2">
              <li>
                <strong>W or Space:</strong> Thrust
              </li>
              <li>
                <strong>A: </strong> Rotate Left
              </li>
              <li>
                <strong>D: </strong> Rotate Right
              </li>
              <li>
                <strong>ESC: </strong> Exit Fullscreen / Stop Application
              </li>
            </ul>
            <br />
            <br />
            <strong>Objective:</strong> Reach the green landing pad without
            hitting any obstacles to advance to the next level.
            <div className="mt-5 flex flex-col justify-center md:flex-row md:justify-between">
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="/projectBoost.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Play Game
                </a>
              </button>
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="https://drive.google.com/file/d/1CU88hJtfSRCDZJZebtynu66PinSYoAXu/view?usp=share_link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download Unity Project
                </a>
              </button>
            </div>
          </p>
        </div>
      </>
    ),
  },
  tcal: {
    shortDescription:
      "An add-on that allows users to update their Twitch stream schedules directly from Google Calendar.",
    modalContent: (
      <>
        <div className="flex px-3 py-5">
          <p>
            This was a side project I decided to build to learn more about
            Twitch API's and Google Workspace add-ons.
            <br />
            <br />
            I sometimes stream on Twitch as a hobby and noticed they had a
            built-in scheduling feature. However, I thought it could have been
            more user-friendly and wanted a better integration with Google
            Calendar, my calendar app of choice.
            <br />
            <br />I decided to build a Google Workspace add-on that would allow
            users to add streams to their Twitch schedule without leaving Google
            Calendar. I had to learn how to use Twitch's developer tools and
            Google Apps Script.
            <br />
            <br />
            The add-on is currently published on the Google Workspace
            Marketplace free of charge.
            <div className="mt-5 flex flex-col justify-center md:flex-row md:justify-between">
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="https://tcal.moki.codes"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View the Add-on
                </a>
              </button>
            </div>
          </p>
        </div>
      </>
    ),
  },
  chronoChannel: {
    shortDescription:
      "A panel extension for Twitch that shows a countdown timer and upcoming streams for a broadcaster.",
    modalContent: (
      <>
        <div className="flex px-3 py-5">
          <p>
            I decided to create Chrono Channel because I couldn't find any
            existing extension that integrates with Twitch's built-in scheduling
            feature. Similar extensions require you to specify a separate stream
            schedule in their configuration, which is redundant.
            <br />
            <br />
            I looked through the Twitch Developer documentation and found that
            there was an API endpoint that returns a broadcaster's schedule.
            <br />
            <br />I mocked up some designs in Figma and started building the
            extension using React, TypeScript, and Tailwind CSS.
            <br />
            <br />
            The extension is currently published on the Twitch Extension
            Directory.
            <br />
            <br />
            <div className="mt-5 flex flex-col justify-center md:flex-row md:justify-between">
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="https://dashboard.twitch.tv/extensions/edbkgj9xjb74cmtvdnvmrn72tmo8fc-0.0.1"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View the Extension
                </a>
              </button>
              <button className="my-2 rounded-lg bg-japaneseIndigo p-2 text-white md:hover:bg-japaneseIndigo-light">
                <a
                  href="https://github.com/iige/chrono-channel"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Source Code on GitHub
                </a>
              </button>
            </div>
          </p>
        </div>
      </>
    ),
  },
};
