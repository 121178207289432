import React from "react";
import githubLogo from "../images/github.svg";
import linkedInLogo from "../images/linkedin.svg";

export class NavigationBar extends React.Component {
  render() {
    const socialItemClass = "mx-3 inline-block";
    const linkItemClass = "mx-3 hover:text-reddishOrange";
    const hamburgerMenuClass = "h-0.5 w-8 bg-white";
    const mobileNavbarItemClass = "py-2";
    const socialIconsJSX = (
      <div>
        <a
          className={socialItemClass}
          href="https://github.com/iige"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={githubLogo} className="fill-white" alt="" />
        </a>
        <a
          className={socialItemClass}
          href="https://www.linkedin.com/in/isaac-ige/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedInLogo} alt="" />
        </a>
      </div>
    );

    function hamburgerMenuHandler() {
      const hamburgerMenuButton = document.getElementById("menu-btn");
      hamburgerMenuButton?.classList.toggle("bg-japaneseIndigo");

      const mobileNav = document.getElementById("mobile-nav");
      mobileNav?.classList.toggle("flex");
      mobileNav?.classList.toggle("hidden");
    }

    function scrollToSection(sectionId: string) {
      const section = document.getElementById(sectionId);
      section?.scrollIntoView({ behavior: "smooth" });
    }

    return (
      <>
        {/* Desktop - Non-Small Devices */}
        <nav className="container relative mx-auto hidden p-4 md:block">
          <div className="flex font-inter text-white md:justify-between">
            {socialIconsJSX}

            <div>
              <button
                className={linkItemClass}
                onClick={() => {
                  scrollToSection("about-section");
                }}
              >
                About
              </button>
              <button
                className={linkItemClass}
                onClick={() => {
                  scrollToSection("skills-section");
                }}
              >
                Skills
              </button>
              <button
                className={linkItemClass}
                onClick={() => {
                  scrollToSection("portfolio-section");
                }}
              >
                Portfolio
              </button>
              <a
                className={linkItemClass}
                href="https://drive.google.com/file/d/1oGVl1MZxpVe9WRu7awGe0MsPoXSoP0AY/view?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resume / CV
              </a>
            </div>
          </div>
        </nav>

        {/* Hamburger Menu for Small Devices */}
        <nav className="container relative mx-auto p-4 md:hidden">
          <div className="flex justify-between">
            {socialIconsJSX}

            <button
              id="menu-btn"
              className="rounded-lg  border-solid border-white p-2"
              onClick={hamburgerMenuHandler}
            >
              <div className="group space-y-2">
                <div className={hamburgerMenuClass}></div>
                <div className={hamburgerMenuClass}></div>
                <div className={hamburgerMenuClass}></div>
              </div>
            </button>
          </div>
          <div
            id="mobile-nav"
            className="mt-3 hidden flex-col rounded-3xl bg-japaneseIndigo text-white"
          >
            <button
              className={mobileNavbarItemClass}
              onClick={() => {
                scrollToSection("about-section");
              }}
            >
              About
            </button>
            <button
              className={mobileNavbarItemClass}
              onClick={() => {
                scrollToSection("skills-section");
              }}
            >
              Skills
            </button>
            <button
              className={mobileNavbarItemClass}
              onClick={() => {
                scrollToSection("portfolio-section");
              }}
            >
              Portfolio
            </button>
            <a
              className={mobileNavbarItemClass + " mx-auto"}
              href="https://drive.google.com/file/d/1yZBYbvrjh7slzBjbQpyTabnUhvfdc0VL/view?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume
            </a>
          </div>
        </nav>
      </>
    );
  }
}
