import React from "react";
import { Modal } from "../Modal";

type SkillProps = {
  image: string;
  classOverrides?: { imageContainer: string }; //Optionally pass down some custom styling to fine tune skill card appearance
  header: string;
  modalContent: JSX.Element;
};

type SkillState = {
  showModal: boolean;
};

export class SkillCard extends React.Component<SkillProps, SkillState> {
  constructor(props: SkillProps) {
    super(props);
    this.state = { showModal: false };
    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    if (this.state.showModal) {
      this.setState({ ...this.state, showModal: false });
    } else {
      this.setState({ ...this.state, showModal: true });
    }
  }

  render() {
    return (
      <>
        {this.state.showModal ? (
          <Modal
            header={this.props.header}
            toggleVisibility={this.toggleModal}
            content={this.props.modalContent}
          />
        ) : null}
        <button
          className="skillCard m-2 basis-1/2 bg-japaneseIndigo py-5 text-center text-white md:basis-1/3 md:hover:bg-japaneseIndigo-light"
          onClick={this.toggleModal}
        >
          <div>
            {this.props.header}
            <div
              className={
                this.props.classOverrides?.imageContainer ?? "mx-auto w-16 pt-4"
              }
            >
              <img src={this.props.image} alt="" />
            </div>
          </div>
        </button>
      </>
    );
  }
}
