import React from "react";

export class Footer extends React.Component {
  render() {
    return (
      <div className="container mx-auto flex flex-col justify-center">
        <div className="bg-black">
          <h1 className="py-5 text-center font-montserrat text-white">
            &copy; 2023 <br />
            All product names, logos, and brands are for identification purposes
            only and are the property of their respective owners.
          </h1>
        </div>
      </div>
    );
  }
}
