import "animate.css";
import { About } from "./components/About";
import { Footer } from "./components/Footer";
import { Hero } from "./components/Hero";
import { NavigationBar } from "./components/Navbar";
import { Projects } from "./components/Projects/Projects";
import { Skills } from "./components/Skills/Skills";

function App() {
  return (
    <>
      <div className="bg-darkBlue brightness-150">
        <NavigationBar />
        <Hero />
      </div>
      <div className="bg-darkBlue">
        <About />
        <Skills />
        <Projects />
      </div>
      <div className="bg-black">
        <Footer />
      </div>
    </>
  );
}

export default App;
